[data-theme="dark"] .react-tel-input .form-control {
    border: 1px solid #BDBDBD !important;
    background: #202534;
    color: #BDBDBD;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    appearance: menulist-button;
    background-color: transparent !important;
    background-image: none !important;
    color: -internal-light-dark(black, white) !important;
}

.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
    border-color: transparent !important;
    box-shadow: none !important;
}

form input:focus {
    border: 2px solid #000;
    outline: none;
}

[data-theme="dark"] form input:focus {
    border: 2px solid #fff;
    outline: none;
}

form input:focus + .svg-inline--fa {
    color: #000;
}

input::placeholder,
textarea::placeholder {
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0.15px;
    color: #c7c7c7;
}

.react-tel-input input.form-control {
    width: 100% !important;
    padding: 0 38px 0 75px !important;
    background: transparent !important;
    border-radius: 4px !important;
    height: 48px !important;
    transition: all 0.2s ease-in-out !important;
    border: 1px solid #BDBDBD;
    box-shadow: none !important;
}

.react-tel-input input.form-control.valid {
    border: 1px solid #000 !important;
}

[data-theme="dark"] .react-tel-input input.form-control.valid {
    border: 1px solid #fff !important;
    color: white;
}

.react-tel-input input.form-control:focus {
    width: 100%;
    padding: 0 38px 0 75px;
    background: transparent;
    border-radius: 4px;
    height: 48px;
    transition: all 0.2s ease-in-out;
    border: 2px solid #000000;
    box-shadow: none !important;
    color: black;
}

[data-theme="dark"] .react-tel-input input.form-control:focus {
    border: 2px solid white !important;
    color: white;
}

.react-tel-input input.form-control.invalid-number{
    width: 100% !important;
    padding: 0 38px 0 75px !important;
    background: transparent !important;
    border-radius: 4px !important;
    height: 48px !important;
    transition: all 0.2s ease-in-out !important;
    border: 2px solid #EB5757;
    box-shadow: none !important;
}

[data-theme="dark"] .react-tel-input input.form-control.invalid-number{
    border: 2px solid #EB5757 !important;
}

.icon-invalid {
    color: #EB5757 !important;
}

.start{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.end{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

@media (max-width: 920px) {
    .end, .start{
        display: flex;
        justify-content: center;
        align-items: center;
    } 

}