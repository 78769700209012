@import "../vars.scss";
$ipad: "max-width:770px";
$celll: "max-width:700px";

div.profile {
    text-align: center;
    text-align: -webkit-center;
    transition: all 1s ease-in-out;
    [data-theme="dark"] & {
        background: #202534;
        color: #ffffff;
    }
    @media ($celll) {
        padding-bottom: 2em;
    }
    .p1 {
        .p2 {
            font-family: Product;
            font-weight: bold;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            padding: 0.9em 0 0.4em 0;
            margin: 0;
            background: -webkit-linear-gradient(
                270deg,
                #5a2682 -10.45%,
                #462a92 24.53%,
                #2a2fa9 76.78%
            );
            transition: all 1.2s ease-in-out;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            [data-theme="dark"] & {
                color: #ffffff;
                -webkit-text-fill-color: unset;
                -webkit-background-clip: unset;
                background: unset;
            }

            @media ($celll) {
                font-size: 18px;
            }
        }
        .p3 {
            font-family: $roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            width: 60%;
            margin: 0.7em auto;
            @media ($ipad) {
                max-width: 85%;
            }
            @media ($celll) {
                font-size: 14px;
                width: 80%;
            }
        }
    }
    .p1-2 {
        display: flex;
        width: 90%;
        align-items: baseline;
        justify-content: space-around;
        margin: 9em auto 0 auto;
        @media ($ipad) {
            display: grid;
            grid-template-columns: 50% 50%;
            grid-template-rows: 50% 50%;
        }
        @media ($celll) {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 2em auto 0 auto;
        }
        .p4 {
            margin: 30px auto;
            .p8 {
                margin: 1.5em 0 0 0;
                font-family: Product;
                font-weight: 700;
                font-size: 24px;
                background: -webkit-linear-gradient(
                    270deg,
                    #5a2682 -10.45%,
                    #462a92 24.53%,
                    #2a2fa9 76.78%
                );
                transition: all 1.2s ease-in-out;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                [data-theme="dark"] & {
                    color: #ffffff;
                    -webkit-text-fill-color: unset;
                    -webkit-background-clip: unset;
                    background: unset;
                }
                @media ($ipad) {
                    font-size: 16px;
                    min-height: 33px;
                }
            }
            .p12 {
                font-family: $roboto;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                margin: 0.8em 0 0 0;
                @media ($celll) {
                    font-size: 14px;
                }
            }
        }
    }
}
