@import "./../vars.scss";

.card-container {
    display: flex;
    width: 33.3%;
    text-align: center;
    // padding: 20px 5px;
    // margin-left: 80px;
    margin-top: 22px;
    margin-bottom: 22px;

    &:nth-child(-n + 3) {
        justify-content: flex-end;
    }

    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9) {
        justify-content: flex-end;
    }

    @media screen and (max-width: $movil) {
        & {
            width: 90%;
            &:nth-child(-n + 3) {
                justify-content: flex-start;
            }

            &:nth-child(7),
            &:nth-child(8),
            &:nth-child(9) {
                justify-content: flex-start;
            }
            &:nth-child(odd) {
                justify-content: flex-end;
            }
        }
    }

    // &:nth-child(6n + 4) {
    //     display: flex;
    //     justify-content: flex-start;
    //     width: calc(100% / 3);
    //     margin-left: 0;
    //     margin-right: 80px;
    //     max-width: 26%;
    // }

    // &:nth-child(7n + 5) {
    //     display: flex;
    //     justify-content: flex-start;
    //     width: calc(100% / 3);
    //     margin-left: 0;
    //     margin-right: 80px;
    //     max-width: 26%;
    // }

    // &:nth-child(8n + 6) {
    //     display: flex;
    //     justify-content: flex-start;
    //     width: calc(100% / 3);
    //     margin-left: 0;
    //     margin-right: 80px;
    //     max-width: 26%;
    // }

    .teamcard {
        position: relative;
        display: flex;
        align-items: center;
        width: 295px;
        @media screen and (max-width: $movil) {
            & {
                width: 223px;
            }
        }
        .info {
            background: linear-gradient(270deg, #77216b 17.46%, #2a2fa9 76.78%);
            box-shadow: 0px 8px 8px rgba(119, 33, 107, 0.2);
            border-radius: 42px;
            padding: 15px 90px 12px 30px;
            color: white;
            text-align: right;
            width: 210px;
            @media screen and (max-width: $movil) {
                & {
                    padding: 3px 32px;
                }
            }
            .name {
                font-family: Product;
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                letter-spacing: 0.01em;
                color: #ffffff;
                @media screen and (max-width: $movil) {
                    & {
                        font-size: 12px;
                        text-align: center;
                        letter-spacing: 0.01em;
                    }
                }
            }
            .charge {
                font-family: $roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                letter-spacing: 0.01em;
                color: #ffffff;
                @media screen and (max-width: $movil) {
                    & {
                        font-size: 10px;
                        text-align: center;
                        letter-spacing: 0.01em;
                    }
                }
            }
        }
        .picture {
            position: absolute;
            width: 85px;
            height: 85px;
            right: 0;
            // top: -5px;
            // border-radius: 50%;
            // background-image: url("./../../assets/img/Team/Clarissa.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            @media screen and (max-width: $movil) {
                & {
                    top: -8px;
                    right: -30px;
                    width: 68px;
                    height: 68px;
                    img {
                        height: 100%;
                        width: 100%;
                    }
                }
            }
        }
    }
}
