$rosa: #77216b;
$morado: #2a2fa9;
$logoUrl: url(../../assets/img/logo.svg);
$logoUrlDark: url(../../assets/img/logoDark.svg);
@import "../vars.scss";

@mixin centrar($direccion: row) {
    display: flex;
    flex-direction: $direccion;
    justify-content: flex-start;
    align-items: center;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    @media (max-width: 764px) {
        width: 5px;
    }
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: linear-gradient(0deg, #77216b, #2a2fa9);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(0deg, #77216cdc, #2a2ea9e3);
}

body {
    margin: 0;
}

.header {
    position: relative;
    height: 100vh;
    z-index: 50;
    background: rgba(255, 255, 255, 0);
    transition: all 1s;
    margin: 0;
    padding-top: 67px;
    @media (max-width: 768px) {
        & {
            height: 70vh;
        }
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        & {
            height: 60vh;
        }
    }
    [data-theme="dark"] & {
        background: #171b2c;
    }
    &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url(../../assets/img/background-light-header-cubes.svg);
        mix-blend-mode: color-burn;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0.45;
        transition: all 1s;
        z-index: 60;
    }
    [data-theme="dark"] &::before {
        opacity: 1;
    }
    div.logo {
        background-image: $logoUrl;
        height: 85px;
        width: 400px;
        margin-left: 12%;
        transition: all 1s ease-in-out;
        @media (max-width: $movil) {
            & {
                display: none;
            }
        }
        [data-theme="dark"] & {
            background-image: $logoUrlDark;
        }
    }
    div.header-content {
        position: absolute;
        height: 100vh;
        width: 100%;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 60;
        @media (max-width: $movil) {
            & {
                height: unset;
            }
        }
        div.container {
            display: flex;
            flex-direction: column;
            align-items: center;

            div.textos {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                h1 {
                    text-align: center;
                    max-width: 70%;
                    min-width: 60%;
                    font-family: Product;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 90px;
                    line-height: 90px;
                    letter-spacing: 0.03em;
                    margin: 35px 0;
                    background: -webkit-linear-gradient(
                        45deg,
                        #2a2fa9,
                        #77216b
                    );
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    transition: all 1.2s ease-in-out;
                    @media (max-width: 768px) {
                        & {
                            font-size: 28px;
                            line-height: 28px;
                        }
                    }
                    @media (min-width: 768px) and (max-width: 1024px) {
                        & {
                            font-size: 47px;
                            line-height: 47px;
                        }
                    }
                    [data-theme="dark"] & {
                        -webkit-text-fill-color: unset;
                        background-clip: unset;
                        -webkit-background-clip: unset;
                        background: unset;
                        color: #fff;
                    }
                }
                h2 {
                    font-family: $roboto;
                    font-weight: 400;
                    color: #263238;
                    text-align: center;
                    width: 50%;
                    margin: 0;
                    margin-bottom: 25px;
                    transition: all 1.2s ease-in-out;
                    @media (max-width: $movil) {
                        & {
                            font-family: Roboto;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 16px;
                            letter-spacing: 0.03em;
                            width: 75%;
                            margin-bottom: 30px;
                        }
                    }
                    @media (min-width: 768px) and (max-width: 1024px) {
                        & {
                            font-size: 20px;
                            line-height: 22px;
                            width: 60%;
                        }
                    }
                    [data-theme="dark"] & {
                        color: #fff;
                    }
                }
            }
            button {
                width: 295px;
                font-weight: bold;
                font-size: 16px;
                line-height: 18px;
                @media (max-width: $movil) {
                    & {
                        width: 190px;
                    }
                }
            }
        }
    }
}
