button.rounded-btn {
  border: none;
  border-radius: 100px;
  transition: all 0.2s ease-in-out;
  font-family: product;
  &:hover {
    cursor: pointer;
    transform: scale(1.03);
  }
  &:focus {
    outline: none;
  }
  &.gradient-btn {
    background: linear-gradient(270deg, #77216b 17.46%, #2a2fa9 76.78%);
    color: #fff;
    padding: 13px 15px;
  }
  &.white-btn {
    background: #ffffff;
    border-radius: 30px;
    box-shadow: 3px 4px 3px black;
    padding: 0.5em 4em;
    font-size: 16px;
    color: #2a2fab;
    font-weight: bold;
  }
  &.white-btnsecond {
    background: #ffffff;
    border-radius: 30px;
    box-shadow: 2px 2px 1.5px black;
    padding: 0.8em 3em;
    font-size: 16px;
    color: #2a2fab;
    font-weight: bold;
  }

  &.disabled-btn {
    background: linear-gradient(270deg, #c5c5c5 17.46%, #aeaeae 76.78%);
    color: #fff;
    padding: 13px 15px;
    cursor: not-allowed !important;
    transform: none !important;
  }
}
