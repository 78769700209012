.modal-container {
    height: 100px;
    width: 150px;
    border-radius: 25px;
    background-color: white;
    margin: auto;
    padding: 0;
    position: fixed;
    top: 20%;
    right: 2%;
    z-index: 1000;
    .modal-card-container {
        height: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
        position: relative;
        border-color: solid 1px black;
        img {
            position: absolute;
            left: 20%;
            top: 50%;
            transform: translateY(-50%);
            height: 50%;
            border-radius: 50%;
        }
        i {
            &:hover {
                cursor: pointer;
            }
        }
        .close {
            position: absolute;
            top: 10px;
            right: 15px;
        }
        .button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 35px;
            &.logged {
                left: unset;
                right: 20%;
                transform: translateY(-50%);
            }
        }
    }
}
