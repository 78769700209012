@import "../vars.scss";
//Variables
$azul-rey: #2a2fab;
$azul-bajo: #432a95;
$morado: #682477;
$rosa: #77216b;
$backgroundfooter: linear-gradient(270deg, #77216b 17.46%, #2a2fa9 76.78%);
$breakpoint-tablet: "max-width:1025px";
$breakpoint-movil: 480px;

.distribution-text { 
  position: absolute;
  margin: 0;
  left: 5%;
  top: 10%;
  width: 200px;
  font-family: "Product";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.03em;
  color: #FFFFFF;
  @media ($breakpoint-tablet) {
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    margin: 0em 1.5em;
    max-width: 50%;
    line-height: 18px;
    bottom: 10%;
    top: unset;
    left: 2%;
  }
}

.map-component {
  position: relative;
  background-image: url("./../../assets/img/newMap.jpeg");
  background-size: contain;
  background-repeat: no-repeat;
  height: 41.75vw;
  width: 100%;
  transition: all 1s ease-in-out;
  @media ($breakpoint-tablet) {
    background-size: cover;
    background-position: center;
    height: 80vw;
  }
}