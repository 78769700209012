@import "../vars.scss";
//Variables
$azul-rey: #2a2fab;
$azul-bajo: #432a95;
$morado: #682477;
$rosa: #77216b;
$backgroundfooter: linear-gradient(270deg, #77216b 17.46%, #2a2fa9 76.78%);
$breakpoint-tablet: 1025px;
$breakpoint-movil: 480px;

.container-footer {
  background: $backgroundfooter;
  color: #ffffff;
  font-family: $roboto;
  font-size: 14px;
  line-height: 16px;
  text-align: -webkit-center !important;
  text-align: -moz-center !important;
  text-align: center;
  width: 100%;

  a {
    color: #ffffff !important;
    text-decoration: none;
  }
  .organization {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    flex-direction: row;
    justify-content: space-around;
    padding: 1.5em;
    padding-top: 2.5em;
    max-width: 90%;
    @media (max-width: $breakpoint-tablet) {
      grid-template-columns: repeat(1, 1fr);
      width: 100%;
      padding: 1.5em;
    }

    .prevfo {
      margin-bottom: 1em;
      @media (max-width: $breakpoint-tablet) {
        width: 100%;
      }

      &.logotype {
        align-self: self-start;
        justify-self: baseline;
        &.p {
          margin: 0px;
        }
        .social {
          display: none;
        }
        @media (max-width: $breakpoint-tablet) {
          margin-top: 1em;
          margin-bottom: 1.6em;
          display: flex;
          justify-content: space-between;
          img {
            max-width: 130px;
          }
          .social {
            display: inline;
            img {
              margin-left: 0.5em;
            }
          }
        }
      }
      &.made {
        .bold {
          text-decoration: none;
        }
      }
      &.talent {
        width: 70%;
        justify-self: center;

        .talentostext {
          margin-top: 0px;
        }

        .footer-btn{
          @media (max-width: $breakpoint-tablet) {
            width: 152px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 15px 0 25px 0;
          }
        }
        @media (max-width: $breakpoint-tablet) {
          justify-self: self-start;
          text-align: left;
          width: 63%;
          font-size: 12px;
        }
      }
      &.contact {
        text-align: left;
        margin-bottom: 1em;
        justify-self: end;
        .talentostext2 {
          margin-top: 0px;
        }
        @media (max-width: $breakpoint-tablet) {
          max-width: 80%;
          justify-self: self-start;
          margin: 0;
        }
      }
      .margimg {
        margin-right: 0.5em;
      }
    }
  }
  .second-container-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1.5em;
    align-items: center;
    max-width: 90%;
    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;
      padding-top: 0;
      max-width: 100;
    }
    .prevfoo {
      margin-bottom: 1em;
      @media (max-width: $breakpoint-tablet) {
        width: 100%;
        max-width: 100%;
      }
      &.made {
        @media (max-width: $breakpoint-tablet) {
          text-align: initial;
        }
        .light {
          font-weight: lighter;
          @media (max-width: $breakpoint-tablet) {
            max-width: 70%;
            margin-top: 0;
          }
        }
        .bold2 {
          display: none;

          @media (max-width: $breakpoint-tablet) {
            display: inline;
            font-weight: 700;
          }
        }
        .bold {
          font-weight: 700;
          @media (max-width: $breakpoint-tablet) {
            display: none;
          }
        }
      }
      &.artesanal {
        p {
          margin: 0px;
          margin-top: -0.8em;
          img {
            margin-left: 0.8em;
            margin-right: 0.2em;
            margin-bottom: -0.5em;
          }
        }
        @media (max-width: $breakpoint-tablet) {
          display: none;
        }
      }
    }
  }
  .lastone {
    display: none;
    @media (max-width: $breakpoint-tablet) {
      display: flex;
      padding-top: 0.8em;
      padding-bottom: 1.2em;
      background: linear-gradient(
        270deg,
        #5a2682 -10.45%,
        #462a92 54.82%,
        #2a2fa9 128.47%
      );
    }
    .prevfooo {
      @media (max-width: $breakpoint-tablet) {
        width: 100%;
        max-width: 100%;
      }
      &.artesanal2 {
        @media (max-width: $breakpoint-tablet) {
          p {
            margin: 0px;

            margin-top: 1em;
            img {
              margin-left: 0.8em;
              margin-right: 0.2em;
              margin-bottom: -0.5em;
            }
          }
        }
      }
    }
  }
}
