$rosa: #77216b;
$morado: #2a2fa9;
$logoUrl: url(../../assets/img/logo.svg);
$logoUrlDark: url(../../assets/img/logoDark.svg);
@import "../vars.scss";

header {
    @media (max-width: 764px) {
        padding-top: 60px !important;
    }
}

// header .logo {
//     display: none;
// }

header {
    position: relative;
    height: 10vh;
    z-index: 50;
    background: rgba(255, 255, 255, 0);
    transition: all 1.2s ease-in-out;
    margin: 0;
    padding-top: 60px;
    [data-theme="dark"] & {
        background: #171b2c;
    }
    div.logo {
        background-image: $logoUrl;
        height: 85px;
        width: 400px;
        margin-left: 12%;
        transition: all 1.2s ease-in-out;
        cursor: pointer;
        [data-theme="dark"] & {
            background-image: $logoUrlDark;
        }
    }
}

.admin-opc {
    position: fixed;
    top: 10%;
    right: 0;
    padding: 15px;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-around;
    border-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid gray;
    font-size: 24px;
    z-index: 100;
    transition: all 1.2s ease-in-out;
    .fas:hover {
        cursor: pointer;
    }
    .fa-edit {
        color: #ffc107;
    }
    .fa-trash {
        color: #dc3545;
    }
}

.article-container {
        transition: all 1.2s ease-in-out;
    [data-theme="dark"] & {
        background: #171b2c;
    }
    @media (max-width: 764px) {
        padding-bottom: 30px;
    }
    .article-wrapper {
        width: 50%;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        @media (max-width: 764px) {
            width: 90%;
        }
        .article-title {
            h1 {
                font-family: Product;
                font-weight: 700;
                font-size: 48px;
                background: -webkit-linear-gradient(45deg, #2a2fa9, #77216b);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                transition: all 1.2s ease-in-out;
                word-break: normal;
                text-align: center;
                [data-theme="dark"] & {
                    -webkit-text-fill-color: unset;
                    background-clip: unset;
                    -webkit-background-clip: unset;
                    background: unset;
                    color: #fff;
                }
                @media (max-width: 764px) {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.01em;
                }
            }
        }
        .article-autor-info {
            display: flex;
            // gap: 25px;
            align-items: center;
            img {
                border-radius: 50%;
                height: 56px;
                width: 56px;
                box-shadow: 0px 8px 8px rgba(119, 33, 107, 0.2);
                margin-right: 15px;
            }
            div {
                display: flex;
                @media (max-width: 764px) {
                    flex-direction: column;
                }
                p {
                    font-family: Product;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 19px;
                    color: #2a2fab;
                    margin: 0;
                    margin-right: 25px;
                    [data-theme="dark"] & {
                        color: white;
                    }
                    @media (max-width: 764px) {
                        margin-right: 5px;
                    }
                    .pre-text {
                        font-weight: 400;
                    }
                }
            }
        }
        .tags {
            display: flex;
            gap: 12px;
            width: 75%;
            margin: 0 auto;
            @media (max-width: 764px) {
                width: 60%;
                margin-top: 10px;
            }
            .tag {
                background: #2a2fab;
                border: 1px solid rgba(0, 0, 0, 0.12);
                box-sizing: border-box;
                border-radius: 16px;
                padding: 4px 12px;
                font-family: roboto;
                font-size: 14px;
                color: #ffffff;
                min-width: 111px;
                transition: all 1s ease-in-out;
                [data-theme="dark"] & {
                    background: #fff;
                    color: #2a2fab;
                }
                @media (max-width: 764px) {
                    font-size: 12px;
                    line-height: 20px;
                }
            }
        }

        .image-article {
            padding: 30px 0;
            @media (max-width: 764px) {
                padding: 60px 0 20px 0;
            }
            img {
                width: 100%;
            }
            hr {
                margin-top: 25px;
                margin-bottom: 12px;
                border: 1px solid #2a2fab;
                background: #2a2fab;
                [data-theme="dark"] & {
                    border: 1px solid white;
                }
            }
            p {
                font-family: Product;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                text-align: justify;
                letter-spacing: 0.01em;
                color: #2a2fab;
                [data-theme="dark"] & {
                    color: white;
                }
                @media (max-width: 764px) {
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 14px;
                }
                span {
                    font-weight: 700;
                }
            }
        }
        p.paragraph {
            margin: 20px 0;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            text-align: justify;
            letter-spacing: 0.01em;
            color: #263238;
            text-transform: lowercase;
            [data-theme="dark"] & {
                color: white;
            }
            @media (max-width: 764px) {
                font-size: 14px;
                line-height: 16px;
                margin: 10px 0;
            }
            &::first-letter {
                text-transform: uppercase;
            }
        }
        q.quote {
            margin: 30px 0;
            font-family: Product;
            font-style: italic;
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            letter-spacing: 0.01em;
            color: #2a2fab;
            text-align: end;
            text-transform: lowercase;
            [data-theme="dark"] & {
                color: white;
            }
            @media (max-width: 764px) {
                font-size: 16px;
                line-height: 19px;
                margin: 10px 0;
                text-align: start;
            }
            &::first-letter {
                text-transform: uppercase;
            }
        }
        .raw {
            margin: 30px 0;
            @media (max-width: 764px) {
                margin: 10px 0;
            }
        }
        iframe {
            margin: 30px 0;
            @media (max-width: 764px) {
                margin: 10px 0;
            }
        }
    }
}

.blog-list{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: justify;
    letter-spacing: 0.01em;
    color: #263238;
    margin: 0;
    [data-theme="dark"] & {
        color: white;
    }
    li{
        margin: 10px 0;
    }
}

.descarga{
    display: flex;
    align-items: center;
    margin: 10px 0;
    width: fit-content;
    font-family: Product;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #747888;
    cursor: pointer;
    transition: all 1.2s, transform .1s ease-in-out;
    &:hover{
        transform: scale(1.03);
        font-weight: 900;
    }
    i{
        margin: 0 10px;
        color: #2A2FAB;
        [data-theme="dark"] & {
            color: white;
        }
    }
    [data-theme="dark"] & {
        color: white;
    }
}

.load-more-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #2A2FAB;
    font-size: 21px;
    padding: 1em 0 3em 0;
    width: fit-content;
    margin: 0 auto;
    cursor: pointer;
    transition: all 1.2s, transform .1s ease-in-out;
    [data-theme="dark"] & {
        color: white;
    }
    & > * + *{
        margin: .6em 0 0 0;
    }

    .circle{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 46px;
        width: 46px;
        border-radius: 50%;
        border: 4px solid #2A2FAB;
        transition: all 1.2s, transform .1s ease-in-out;
        [data-theme="dark"] & {
            border: 4px solid white;
        }
        &:hover{
            transform: scale(1.05);
        }
    } 
    p{
        font-family: Product;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.18px;
        transition: all 1.2s, transform .1s ease-in-out;
        &:hover{
            transform: scale(1.05);
        }
    }
}

.blog-wrapper-related {
    position: relative;
    transition: all 1.2s ease-in-out;
    [data-theme="dark"] & {
        background: #171b2c;
    }
    .blog-container-related {
        position: relative;
        width: 86%;
        margin: 0 auto;
        align-content: center;
        z-index: 10;
        @media screen and (max-width: $movil) {
            & {
                width: 90%;
            }
        }
        .blog-title-related{
            display: flex;
            justify-content: center;

            p {
                font-family: Product;
                font-style: normal;
                font-weight: bold;
                font-size: 30px;
                line-height: 36px;
                text-align: center;
                letter-spacing: 0.01em;
                margin: 2em 0 2em 0;
                transition: all 1.2s ease-in-out;
                color: #2A2FAB;
                [data-theme="dark"] & {
                    color: white;
                }
                @media screen and (max-width: $movil) {
                    & {
                        font-family: Product;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 26px;
                        line-height: 26px;
                        text-align: center;
                        letter-spacing: 0.03em;
                        margin: 60px 0;
                    }
                }
            }
        }
        .posts-container{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
}
