@import "../vars.scss";

.team-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 1.2s ease-in-out;
    @media screen and (max-width: $movil) {
        & {
            align-items: flex-start;
        }
    }
    [data-theme="dark"] & {
        background: #202534;
        color: #fff;
    }
    &::before {
        content: "";
        display: block;
        position: absolute;
        background-image: url("./../../assets/img/vectorFondo.svg");
        background-size: 100vw;
        opacity: 0.04;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        box-shadow: inset 0px -300px 250px -118px #fff;
        z-index: 1;
        transition: all 1.2s ease-in-out;
        [data-theme="dark"] & {
            box-shadow: inset 0px -300px 250px -118px #202534;
            opacity: 0.3;
        }
    }
    .title {
        width: 100%;
        padding-top: 60px;
        font-family: Product;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #2a2fab;
        z-index: 10;
        [data-theme="dark"] & {
            color: #fff;
        }
        h2 {
            width: 100%;
            text-align: center;
            margin: 0;
            @media screen and (max-width: $movil) {
                & {
                    font-size: 18px;
                    text-align: center;
                    letter-spacing: 0.01em;
                }
            }
        }
    }
    .team {
        width: 95%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        // gap: 60px 0;
        justify-content: center;
        padding: 60px 0;
        z-index: 10;
        @media (max-width: $movil) {
            & {
                width: 95%;
                margin: 0;
                display: flex;
                flex-wrap: wrap;
                // gap: 45px 0;
                justify-content: center;
            }
        }
    }
}
