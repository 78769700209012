@import "./../vars.scss";

.link-card {
    position: relative;
    box-sizing: border-box;
    border-radius: 20px;
    width: calc(50% - 10px);
    height: 400px;
    margin-bottom: 40px;
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    text-decoration: none;
    @media screen and (max-width: $movil) {
        & {
            width: 100%;
            height: 65vw;
        }
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        & {
            width: 45vw;
            height: 30vw;
        }
    }

    .card {
        position: relative;
        height: 400px;
        z-index: 50;
        @media screen and (max-width: $movil) {
            & {
                height: 65vw;
            }
        }
        @media (min-width: 768px) and (max-width: 1024px) {
            & {
                width: 45vw;
                height: 30vw;
            }
        }
        .gradient {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            height: 100%;
            width: 100%;
            margin: 0;
            padding: 17px 40px;
            border: 1px solid #2a2fab;
            box-sizing: border-box;
            border-radius: 20px;
            background-blend-mode: multiply, normal;
            mix-blend-mode: normal;
            background-repeat: no-repeat;
            background-size: cover;
            mix-blend-mode: normal;
            transition: all 0.3s ease-in-out;
            z-index: 10;
            @media screen and (max-width: $movil) {
                & {
                    padding: 15px;
                }
            }
            &:hover {
                transform: scale(1.01);
                cursor: pointer;
            }
        }
        .share-container{
            position: absolute;
            top: 20px;
            right: 15px;
            width: 56px;
            height: 40px;
            border-radius: 10px;
            background: #2A2FAB;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 400;
            opacity: 0.9;
            .tooltip {
                position: relative;
                display: inline-block;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .tooltiptext {
                    visibility: hidden;
                    width: 140px;
                    background: #2A2FAB;
                    color: #fff;
                    text-align: center;
                    border-radius: 6px;
                    padding: 5px;
                    font-family: Product;
                    font-size: 14px;
                    position: absolute;
                    z-index: 100;
                    top: 50%;
                    right: 110%;
                    transform: translateY(-50%);
                    margin-left: -75px;
                    opacity: 0;
                    transition: all 0.3s ease-in-out;
                    &::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 100%;
                        transform: translateY(-50%);
                        margin-left: 0px;
                        border-width: 5px;
                        border-style: solid;
                        border-color: transparent transparent transparent #77216B;
                    }
                }

                &:hover .tooltiptext {
                    visibility: visible;
                    opacity: 1;
                }

                i{
                    color: white;
                    font-size: 19px;
                }
            }
        }
        .watch-on-youtube{
            position: absolute;
            bottom: 5%;
            right: 5%;
            display: flex;
            align-items: center;
            font-family: Product;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            display: flex;
            letter-spacing: 0.03em;
            color: #FFFFFF;
            cursor: pointer;
            z-index: 500;
            text-decoration: none;
            svg{
                margin-right: 4px;
            }
        }
        .article-data-container {
            position: absolute;
            bottom: 5%;
            left: 5%;
            width: 100%;
            height: fit-content;
            display: flex;
            flex-direction: column;
            // gap: 20px;
            .title {
                z-index: 2;
                font-family: Product;
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                letter-spacing: 0.01em;
                color: #ecf2ff;
                width: 90%;
                text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.4);
                margin-bottom: 20px;
            }
        }
        .bottom {
            display: flex;
            // gap: 10px;
            .tags {
                display: flex;
                // gap: 12px;
                width: 50%;
                margin-right: 10px;
                // @media screen and (max-width: $movil) {
                //     & {
                //         // flex-wrap: wrap;
                //     }
                // }
                .tag {
                    background: #ededed;
                    border: 1px solid rgba(0, 0, 0, 0.12);
                    box-sizing: border-box;
                    border-radius: 16px;
                    padding: 4px 12px;
                    font-family: roboto;
                    color: #263238;
                    margin-right: 12px;
                }
            }
            .icons {
                display: flex;
                justify-content: center;
                align-items: center;
                // gap: 30px;
                width: 50%;
                color: #ededed;
                font-size: 20px;
                text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.4);
                i {
                    margin-right: 30px;
                }
                @media screen and (max-width: $movil) {
                    & {
                        flex-wrap: wrap;
                        justify-content: space-between;
                        display: none;
                    }
                }
            }
        }
        
    }
}
